<ag-shr-modal-window
  #agencyAddressFormModalRef
  [title]="'Agency Address'"
  [width]="'80vw'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
  [showSaveButton]="true"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="handleSaveAgencyAddress($event)"
>
  <dx-form
    #agencyAddressFormRef
    *ngIf="agencyAddressFormModalRef?.popupComponent?.visible ?? false"
    [(formData)]="agencyAddressFormData"
    [colCount]="12"
    labelMode="floating"
  >
    <dxi-item
      [colSpan]="4"
      editorType="dxSelectBox"
      [dataField]="AddressModelKeys.addressType"
      [label]="{ text: 'Type' }"
      [editorOptions]="{
        items: agencyAddressTypesLookup,
        valueExpr: BaseModelKeys.dbId,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: '',
        onSelectionChanged: onSelectedAddressTypesChanged
      }"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.isPhysicalLocation"
      [alignItemLabels]="true"
      [colSpan]="4"
      [label]="{ text: 'Physical Location' }"
      editorType="dxSwitch"
      [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
    ></dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.isPrimaryShipping"
      [alignItemLabels]="true"
      [colSpan]="4"
      [label]="{ text: 'Shipping' }"
      editorType="dxSwitch"
      [editorOptions]="{ switchedOffText: 'No', switchedOnText: 'Yes' }"
    ></dxi-item>

    <dxi-item [dataField]="AddressModelKeys.address1" [colSpan]="12">
      <dxi-validation-rule type="required" [message]="Messages.REQUIRED_ADDRESS_ONE_TEXT"></dxi-validation-rule>
    </dxi-item>

    <dxi-item [dataField]="AddressModelKeys.address2" [colSpan]="12"></dxi-item>

    <dxi-item [dataField]="AddressModelKeys.city" [colSpan]="4"></dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.state"
      [colSpan]="4"
      editorType="dxSelectBox"
      [label]="{ text: 'State' }"
      [editorOptions]="{
        items: statesLookup,
        valueExpr: BaseModelKeys.dbId,
        displayExpr: LookupKeys.description,
        searchEnabled: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item [dataField]="AddressModelKeys.zip" [colSpan]="4">
      <dxi-validation-rule
        type="pattern"
        [pattern]="usZipRegExp"
        [message]="Messages.INVALID_ZIP_FORMAT_TEXT"
      ></dxi-validation-rule>
    </dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.country"
      [colSpan]="4"
      editorType="dxSelectBox"
      [label]="{ text: 'Country' }"
      [editorOptions]="{
        items: countries,
        displayExpr: 'name',
        valueExpr: 'code',
        searchEnabled: true,
        placeholder: ''
      }"
    ></dxi-item>

    <dxi-item [dataField]="AddressModelKeys.county" [colSpan]="4"></dxi-item>
  </dx-form>
</ag-shr-modal-window>
