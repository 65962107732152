import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ActiveLookup,
  Address,
  AddressModelKeys,
  BaseModelKeys,
  BUSINESS_PERSONAL_TYPE,
  COUNTRIES,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  LookupKeys,
  Messages,
} from '@ag-common-lib/public-api';
import { LookupsService } from '../../../../../services';
import { FullAddressPipe } from '../../../../../../shared/pipes/full-address.pipe';
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { AgencyAddressModalComponent } from '../address-modal/agency-address-modal.component';
import * as _ from 'lodash';
import { PortalDataService } from '../../../../../services/portal.service';
import { tap } from 'rxjs';
import { AgencyAddressesService } from '../agency-addresses.service';
import { RowRemovingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'ag-shr-agency-addresses-grid',
  templateUrl: './addresses-grid.component.html',
  styleUrls: ['./addresses-grid.component.scss'],
  providers: [FullAddressPipe],
})
export class AddressesGridComponent {
  @ViewChild('addressesGridREf', { static: false }) addressGridComponent: DxDataGridComponent;
  @ViewChild('agencyAddressModalRef') agencyAddressModalComponent: AgencyAddressModalComponent;
  @Input() addresses: Address[] = [];
  @Input() agencyId: string;
  @Input() isEditable: boolean = true;
  @Output() addressesChange = new EventEmitter<Address[]>();

  statesLookup$ = this.lookupsService.statesLookup$;
  agencyAddressTypesLookup$ = this.lookupsService.agencyAddressTypesLookup$.pipe(
    tap(items => {
      this._businessAddressTypeLookup = items?.find(item => item?.value === BUSINESS_PERSONAL_TYPE.BUSINESS);
    }),
  );
  inProgress$ = this.agencyAddressesService.inProgress$;
  private _businessAddressTypeLookup: ActiveLookup;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly Messages = Messages;
  protected readonly countries = COUNTRIES;
  protected readonly AddressModelKeys = AddressModelKeys;
  readonly emptyMessage = 'No Addresses Currently Exist';
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private readonly lookupsService: LookupsService,
    public fullAddressPipe: FullAddressPipe,
    private toastrService: ToastrService,
    public portalDataService: PortalDataService,
    private agencyAddressesService: AgencyAddressesService,
  ) {}

  canDeleteRow = e => !e.row.data.is_primary_billing && !e.row.data.is_primary_shipping;

  onRowRemoving = (e: RowRemovingEvent) => {
    const addresses = this.addresses.filter(address => address !== e.key);
    e.cancel = this.updateAddresses(addresses);
  };

  onEditorPreparing = e => {
    if (e.parentType !== 'dataRow') {
      return;
    }

    if (e.dataField === 'is_primary_shipping') {
      e.editorOptions.disabled = e.row.data.is_primary_shipping;
    }

    if (e.dataField === 'is_physical_location') {
      e.editorOptions.disabled = e.row.data.is_physical_location;
    }
  };

  onAddressChange(agencyAddress: Address): void {
    const isUniq = this.checkIsAddressUniq(agencyAddress);
    if (!isUniq) {
      this.toastrService.error('Same address already exists in this agency');
      return;
    }

    const addresses: Address[] = this.normalizeAddresses(agencyAddress);
    if (!addresses.length || !_.find(addresses, { id: agencyAddress.id })) {
      addresses.push(Object.assign({}, agencyAddress)); // to add
    }
    this.updateAddresses(addresses);
  }

  showAddPopup = (): void => {
    const initialData: Address = {
      [AddressModelKeys.id]: this.portalDataService.getUUID(),
      [AddressModelKeys.isPrimaryShipping]: !this.addresses?.length,
      [AddressModelKeys.isPhysicalLocation]: false,
      [AddressModelKeys.addressType]: this._businessAddressTypeLookup?.dbId,
      [AddressModelKeys.country]: 'US',
    };
    this.agencyAddressModalComponent.showModal(this.agencyId, initialData);
  };

  showEditPopup = ({ row: { data } }): void => {
    this.agencyAddressModalComponent.showModal(this.agencyId, data);
  };

  private normalizeAddresses = (data, key?: Address) => {
    const isPrimaryBilling = data?.is_primary_billing;
    const isPrimaryShipping = data?.is_primary_shipping;
    const isPhysicalLocation = data?.is_physical_location;

    return this.addresses.map(address => {
      if (key && address === key) {
        return data;
      }

      const normalizedAddress = Object.assign({}, address);
      if (isPrimaryBilling) {
        Object.assign(normalizedAddress, { is_primary_billing: false });
      }
      if (isPrimaryShipping) {
        Object.assign(normalizedAddress, { is_primary_shipping: false });
      }
      if (isPhysicalLocation) {
        Object.assign(normalizedAddress, { is_physical_location: false });
      }

      return normalizedAddress;
    });
  };

  private updateAddresses = (addresses: Address[]) => {
    return this.agencyAddressesService.updateAddress(this.agencyId, addresses).then(() => {
      this.addresses = addresses;
      this.addressesChange.emit(addresses);
      this.agencyAddressModalComponent.hideModal();
    });
  };

  private checkIsAddressUniq = (data, key?: Address) => {
    const addressString = this.fullAddressPipe.transform(data);
    return this.addresses.every(address => {
      if (key && address === key) {
        return true;
      }

      return this.fullAddressPipe.transform(address)?.toLocaleLowerCase() !== addressString?.toLocaleLowerCase();
    });
  };
  protected readonly Entity = Entity;
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
}
