<div class="mt-2">
  <ag-shr-editor-toolbar
    [title]="'Agency Addresses'"
    (clickEdit)="agencyAddressesModalComponent.showModal()"
    [isEditVisible]="Entity.agencyContactInfoAddresses | hasPermission : EntityPermissionActivityKeys.write | async"
  ></ag-shr-editor-toolbar>

  <ag-shr-agency-addresses-grid [addresses]="addresses" [isEditable]="false"></ag-shr-agency-addresses-grid>
</div>

<ag-shr-modal-window
  #agencyAddressesModalRef
  [title]="'Add / Edit Agency Addresses'"
  [showSaveButton]="false"
  [width]="'75%'"
  [height]="'auto'"
  [inProgress]="inProgress$ | async"
>
  <ag-shr-agency-addresses-grid
    [agencyId]="agencyId"
    [addresses]="addresses ?? []"
    (addressesChange)="onAddressesChange($event)"
  ></ag-shr-agency-addresses-grid>
</ag-shr-modal-window>
