import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgMediaUploaderComponent } from './ag-media-uploader.component';
import { ButtonWithIndicatorModule, ModalWindowModule } from '../../components';
import { AgTabsModule } from '../ag-tabs/ag-tabs.module';
import { UploadFromDiskTabComponent } from '././upload-from-disk-tab/upload-from-disk-tab.component';
import { DropZoneModule } from '../../components/drop-zone/drop-zone.module';
import { UploadFromUrlTabComponent } from './upload-from-url-tab/upload-from-url-tab.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormsModule } from '@angular/forms';
import {
  DxButtonModule,
  DxFileUploaderModule,
  DxFormModule,
  DxTextBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { SelectFromGalleryComponent } from './select-from-gallery/select-from-gallery.component';
import { AgMediaGalleryModule } from '../ag-media-gallery/ag-media-gallery.module';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    AgMediaUploaderComponent,
    UploadFromDiskTabComponent,
    UploadFromUrlTabComponent,
    SelectFromGalleryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ModalWindowModule,
    AgTabsModule,
    DropZoneModule,
    ImageCropperModule,
    FormsModule,
    DxFormModule,
    DxTextBoxModule,
    DxButtonModule,
    ButtonWithIndicatorModule,
    DxFileUploaderModule,
    DxValidatorModule,
    ImagePreviewComponent,
    AgMediaGalleryModule,
  ],
  exports: [AgMediaUploaderComponent],
})
export class AgMediaUploaderModule {}
