import { Inject, Injectable } from '@angular/core';
import { Agent, AgentKeys, BaseModelKeys } from '@ag-common-lib/public-api';
import { FirebaseApp } from 'firebase/app';
import { collectionGroup, getDocs, query, QueryConstraint, where } from 'firebase/firestore';
import { QueryParam, WhereFilterOperandKeys } from '../../../public-api';
import { FIREBASE_APP } from '../../injections/firebase-app';
import { DataService } from '../data.service';
import { agentFromFirestore } from './agent-service-utils';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends DataService<Agent> {
  private readonly emailAddressCollectionPath = 'email-addresses';

  constructor(@Inject(FIREBASE_APP) fireBaseApp: FirebaseApp) {
    super(fireBaseApp, AgentService.fromFirestore, AgentService.toFirestore);
    super.setCollection('agents');
  }
  static readonly fromFirestore = agentFromFirestore;

  static readonly toFirestore = (data): Agent => {
    return data[AgentKeys.p_email]
      ? Object.assign(data, {
          [AgentKeys.p_email]: data[AgentKeys.p_email].toLowerCase().trim(),
        })
      : data;
  };

  public updateFields(documentId: string, data: Partial<Agent>): Promise<Agent> {
    if (AgentKeys.agent_review_level in data) {
      Object.assign(data, {
        [AgentKeys.registrant_review_level_update_date]: new Date(),
      });
    }

    if (AgentKeys.prospect_status in data) {
      Object.assign(data, {
        [AgentKeys.prospect_status_update_date]: new Date(),
      });
    }

    return super.updateFields(documentId, data);
  }

  public findAgentByLoginEmail = async email => {
    const queries: QueryParam[] = [];

    const emailAddressQuery = new QueryParam('address', WhereFilterOperandKeys.equal, email);
    const isLoginQuery = new QueryParam('is_login', WhereFilterOperandKeys.equal, true);

    queries.push(emailAddressQuery);
    queries.push(isLoginQuery);

    const queryConstraints: QueryConstraint[] = queries.map(query => where(query.field, query.operation, query.value));

    const collectionGroupRef = collectionGroup(this.fsDao.db, this.emailAddressCollectionPath).withConverter({
      toFirestore: null,
      fromFirestore: this.fsDao.convertResponse,
    });

    const collectionGroupQuery = query(collectionGroupRef, ...queryConstraints);
    const querySnapshot = await getDocs(collectionGroupQuery);

    if (!querySnapshot.size) {
      return null;
    }
    if (querySnapshot.size > 1) {
      throw new Error('More That One Agents With same login email was found');
    }

    const document = querySnapshot.docs[0];
    const parentDbId = document?.ref?.parent?.parent?.id;

    return this.fsDao.getById(this.collection, parentDbId);
  };

  getAgentByEmail(email: string): Promise<Agent> {
    return this.getAllByValue([new QueryParam('p_email', WhereFilterOperandKeys.equal, email)]).then(agents => {
      if (agents.length == 0) {
        return null;
      } else if (agents.length == 1) {
        return agents[0];
      } else {
        console.error('More than 1 agent found with this email address');
        return null;
      }
    });
  }

  getAgentByAuthUID(uid: string): Promise<Agent> {
    return this.getAllByValue([new QueryParam(AgentKeys.uid, WhereFilterOperandKeys.equal, uid)]).then(agents => {
      return agents[0];
    });
  }

  getAgentByAgentId(id: string): Promise<Agent> {
    return this.getAllByValue([new QueryParam('p_agent_id', WhereFilterOperandKeys.equal, id)]).then(agents => {
      if (agents.length == 0) {
        return null;
      } else if (agents.length == 1) {
        return agents[0];
      } else {
        console.error('More than 1 agent found with this agent id');
        return null;
      }
    });
  }

  getAgentsByAgentIds(ids: string[], sortField: string = 'p_agent_last_name'): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam(BaseModelKeys.dbId, WhereFilterOperandKeys.in, ids)], sortField);
  }

  getAgentForChristmasCardList(): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam(AgentKeys.christmas_card, WhereFilterOperandKeys.equal, true)]);
  }

  getAgentForConferencePosterList(): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam(AgentKeys.conference_poster, WhereFilterOperandKeys.equal, true)]);
  }

  getAgentsByAgencyId(id: string, sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('p_agency_id', WhereFilterOperandKeys.equal, id)], sortField);
  }

  getAgentsByProspectStatuses(id: string[], sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('prospect_status', WhereFilterOperandKeys.in, id)], sortField);
  }

  getAgentsByAgentStatuses(id: string[], sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('agent_status', WhereFilterOperandKeys.in, id)], sortField);
  }

  getMGAsByMGAId(id: string, sortField: string): Promise<Agent[]> {
    return this.getAllByValue([new QueryParam('p_mga_id', WhereFilterOperandKeys.equal, id)], sortField);
  }

  getManagersByMGAId(id: string, sortField: string): Promise<Agent[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('is_manager', WhereFilterOperandKeys.equal, true));
    qp.push(new QueryParam('p_mga_id', WhereFilterOperandKeys.equal, id));

    return this.getAllByValue(qp, sortField);
  }

  getManagersByAgencyId(id: string, sortField: string): Promise<Agent[]> {
    let qp: QueryParam[] = [];
    qp.push(new QueryParam('is_manager', WhereFilterOperandKeys.equal, true));
    qp.push(new QueryParam('p_agency_id', WhereFilterOperandKeys.equal, id));

    return this.getAllByValue(qp, sortField);
  }
}
