import { Injectable } from '@angular/core';
import { Address, Agency, Lookup, LookupKeys } from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { AgencyService } from '../../../../services/agency.service';
import { BaseFormService } from '../../../../utils/base-form-service';

@Injectable()
export class AgencyAddressesService extends BaseFormService<Address> {
  selectedAgencyType$ = new BehaviorSubject<Lookup>(null);

  constructor(private agencyService: AgencyService) {
    super();
  }

  getFormData = async (address?: Partial<Address>): Promise<Address> => {
    const initialData = Object.assign({}, address);
    this.formData = new Proxy(initialData, {
      set: (target, prop, value, receiver) => {
        const prevValue = target[prop];
        this.formChangesDetector.handleChange(prop, value, prevValue);
        Reflect.set(target, prop, value, receiver);

        return true;
      },
    });

    return this.formData;
  };

  updateAddress(agencyId: string, addresses: Address[]): Promise<Agency> {
    this.startProgress();
    this.formChangesDetector.clear();
    return this.agencyService
      .updateFields(agencyId, { addresses })
      .then(response => {
        const selectedAgencyType = this.selectedAgencyType$?.value;
        if (selectedAgencyType && !selectedAgencyType?.isAssigned) {
          updateDoc(selectedAgencyType?.reference as DocumentReference<Lookup>, {
            [LookupKeys.isAssigned]: true,
          }).then();
        }
        return response;
      })
      .finally(() => {
        this.stopProgress();
      });
  }

  setSelectedAgencyType(item): void {
    this.selectedAgencyType$.next(item);
  }
}
