import { Address } from '@ag-common-lib/public-api';

export const getAddressKeyExpression = (address: Address) => {
  if (!address) {
    return null;
  }
  const keyItems = [
    address?.address1,
    address?.address2,
    address?.city,
    // address?.state,
    // address?.zip,
    // address?.country,
    // address?.county,
  ];

  if (!keyItems.some(Boolean)) {
    return null;
  }

  return keyItems.join(',');
};
