import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAddressComponent } from './select-address.component';
import { SharedModule } from '../../../shared/shared.module';
import { IsNewAddressSelectedPipe } from './is-new-address-selected.pipe';
import { AddressFormModule } from '../address-form/address-form.module';

@NgModule({
  declarations: [SelectAddressComponent, IsNewAddressSelectedPipe],
  imports: [CommonModule, SharedModule, AddressFormModule],
  exports: [SelectAddressComponent],
})
export class SelectAddressModule {}
