import { Injectable } from '@angular/core';
import {
  Address,
  AddressModelKeys,
  AgentMedia,
  AGMediaKeys,
  Association,
  AssociationKeys,
  BaseModelKeys,
  BUSINESS_PERSONAL_TYPE,
  CampaignLogoHeadshot,
  CampaignSocialMedia,
  EmailAddress,
  EmailAddressKeys,
  MediaContentType,
  PhoneNumber,
  PhoneNumberKeys,
  Social,
  SocialKeys,
} from '@ag-common-lib/public-api';

import { firstValueFrom, map } from 'rxjs';
import { getAddressKeyExpression } from 'ag-common-svc/lib/utils/address.util';
import { LookupsService } from '../services';
import { AgentEmailAddressesService } from '../services/agent-email-addresses.service';
import { AgentImagesService } from '../services/agent-images.service';
import { getPhoneNumberKeyExpression } from '../components/select-phone-number/select-phone-number.service';
import { omit } from 'lodash';
import { AgentAssociationsService } from '../services/agent-associations.service';

export class AgentProfileDataService {
  constructor(
    protected readonly lookupsService: LookupsService,
    protected readonly agentEmailAddressesService: AgentEmailAddressesService,
    protected readonly agentAssociationsService: AgentAssociationsService,
    protected readonly agentImagesUtil: AgentImagesService,
  ) {}

  protected handleAgentAddress = (address: Partial<Address>, agentAddresses: Address[]): Address[] => {
    if (!agentAddresses?.length) {
      return [
        Object.assign({}, new Address(), address, {
          [AddressModelKeys.addressType]: BUSINESS_PERSONAL_TYPE.BUSINESS,
          [AddressModelKeys.isPrimaryBilling]: true,
          [AddressModelKeys.isPrimaryShipping]: true,
        }),
      ];
    }

    const addressKeyExpression = getAddressKeyExpression(address);
    const existedAddress = agentAddresses.find(agentAddress => {
      const agentAddressKeyExpression = getAddressKeyExpression(agentAddress);

      return addressKeyExpression === agentAddressKeyExpression;
    });

    existedAddress
      ? Object.assign(
          existedAddress,
          omit(address, [AddressModelKeys.isPrimaryBilling, AddressModelKeys.isPrimaryShipping]),
        )
      : agentAddresses.push(
          Object.assign({}, new Address(), address, {
            [AddressModelKeys.addressType]: BUSINESS_PERSONAL_TYPE.BUSINESS,
          }),
        );

    return agentAddresses;
  };

  protected handleAgentPhoneNumber = (
    phoneNumber: Partial<PhoneNumber>,
    agentPhoneNumbers: PhoneNumber[],
  ): PhoneNumber[] => {
    if (!agentPhoneNumbers?.length) {
      return [
        Object.assign({}, new PhoneNumber(), phoneNumber, {
          [PhoneNumberKeys.is_primary]: true,
        }),
      ];
    }

    const existedPhoneNumber = agentPhoneNumbers.find(agentPhoneNumber => {
      const agentPhoneNumberKeyExpression = getPhoneNumberKeyExpression(agentPhoneNumber);

      return getPhoneNumberKeyExpression(phoneNumber) === agentPhoneNumberKeyExpression;
    });

    existedPhoneNumber
      ? Object.assign(existedPhoneNumber, omit(phoneNumber, [PhoneNumberKeys.is_primary]))
      : agentPhoneNumbers.push(Object.assign({}, new PhoneNumber(), omit(phoneNumber, [PhoneNumberKeys.is_primary])));

    return agentPhoneNumbers;
  };

  protected handleAgentSocials = async (
    campaignSocialMedia: Partial<CampaignSocialMedia>,
    agentSocials: Social[],
  ): Promise<Social[]> => {
    const defaultSocialTypesLookup = await firstValueFrom(
      this.lookupsService.socialTypesLookup$.pipe(
        map(socialTypes => socialTypes?.find(socialType => socialType?.isDefault)),
      ),
    );

    const newSocials = [];

    Object.entries(campaignSocialMedia)
      .filter(([_key, value]) => !!value)
      .forEach(([media, url]) => {
        const isMediaExist = agentSocials?.length && agentSocials.some(media => media.url === url);

        if (!isMediaExist) {
          newSocials.push(
            Object.assign({}, new Social(), {
              [SocialKeys.url]: url,
              [SocialKeys.socialType]: defaultSocialTypesLookup,
              [SocialKeys.socialMedia]: media,
            }),
          );
        }
      });

    return [...agentSocials, ...newSocials];
  };

  protected handleAgentEmailAddress = async (emailAddress: Partial<EmailAddress>, agentDbId: string) => {
    if (emailAddress?.[EmailAddressKeys.isLogin]) {
      return;
    }

    if (emailAddress?.[BaseModelKeys.dbId]) {
      return this.agentEmailAddressesService.update(
        agentDbId,
        emailAddress?.[BaseModelKeys.dbId],
        {
          [EmailAddressKeys.address]: emailAddress?.[EmailAddressKeys.address],
        },
        true,
      );
    }
    const defaultEmailTypeLookup = await firstValueFrom(
      this.lookupsService.emailTypeLookup$.pipe(map(emailTypes => emailTypes?.find(emailType => emailType?.isDefault))),
    );

    return this.agentEmailAddressesService.create(
      agentDbId,
      {
        [EmailAddressKeys.agentDbId]: agentDbId,
        [EmailAddressKeys.emailType]: defaultEmailTypeLookup?.dbId,
        [EmailAddressKeys.address]: emailAddress?.[EmailAddressKeys.address],
      },
      true,
    );
  };

  protected handleAgentAssociation = async (association: Partial<Association>, agentDbId: string) => {
    if (association?.[BaseModelKeys.dbId]) {
      return this.agentAssociationsService.update(
        agentDbId,
        association?.[BaseModelKeys.dbId],
        omit(association, [AssociationKeys.firstName, AssociationKeys.lastName]),
        true,
      );
    }

    return this.agentAssociationsService.create(agentDbId, Object.assign({}, new Association(), association), true);
  };

  protected handleAgentLogoHeadshot = (
    campaignLogoHeadshot: Partial<CampaignLogoHeadshot>,
    agentDbId: string,
  ): Promise<AgentMedia | void>[] => {
    return Object.values(campaignLogoHeadshot)
      .filter(item => item && !item?.[BaseModelKeys.dbId])
      .map(item => {
        const media = Object.assign({}, new AgentMedia(MediaContentType.image, agentDbId), {
          [AGMediaKeys.contentType]: item?.contentType ?? null,
          [AGMediaKeys.wasabiPath]: item?.wasabiPath ?? null,
          [AGMediaKeys.fileName]: item?.fileName ?? null,
        });
        return this.agentImagesUtil.addAgentMediaToImagesDirectory(agentDbId, media);
      });
  };
}
