import { Pipe, PipeTransform } from '@angular/core';
import { Agent, BUSINESS_PERSONAL_TYPE, Social, SocialKeys, Website, WebsiteKeys } from 'ag-common-lib/public-api';
import { LookupsService } from '../../lib/services';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'agentMainInfoSocials' })
export class AgentMainInfoSocialsPipe implements PipeTransform {
  constructor(private lookupsService: LookupsService) {}

  transform(input: Agent): Observable<any[]> {
    return combineLatest([this.lookupsService.websiteTypesLookup$, this.lookupsService.socialTypesLookup$]).pipe(
      map(([websiteTypesLookup, socialTypesLookup]) => {
        let links = [];
        const socials = input.socials;
        const websites = input.websites;
        const businessSocialType = socialTypesLookup?.find(
          type => type.value === BUSINESS_PERSONAL_TYPE.BUSINESS,
        )?.dbId;
        const businessWebsiteType = websiteTypesLookup?.find(
          type => type.value === BUSINESS_PERSONAL_TYPE.BUSINESS,
        )?.dbId;

        // Social links
        if (Array.isArray(socials)) {
          const socialLinks = this.createSocialLinks(socials, businessSocialType);
          links = [...links, ...socialLinks];
        }
        // Website links
        if (Array.isArray(websites)) {
          const websiteLinks = this.createWebsiteLinks(websites, businessWebsiteType);
          links = [...links, ...websiteLinks];
        }
        return links;
      }),
    );
  }

  private createSocialLinks(socials: Social[], defaultType: string): any[] {
    const socialLinks = [];
    const socialsMap = new Map();
    const iconMap = {
      FB: 'agi-facebook-f',
      IG: 'agi-instagram',
      LI: 'agi-linkedin-in',
      TW: 'agi-twitter',
      AC: 'assets/ag-logo-sm-blue.svg',
    };

    socials.forEach(social => {
      const socialMedia = socialsMap.get(social.social_media);
      // skip if already added business media
      if (socialMedia && socialMedia?.social_type === defaultType) {
        return;
      }
      // skip if already added media but new one not a business type
      if (socialMedia && social?.social_type !== defaultType) {
        return;
      }

      socialsMap.set(social[SocialKeys.socialMedia], social);
    });

    socialsMap.forEach(social => {
      socialLinks.push({
        icon: iconMap[social.social_media] ?? 'link',
        onClick: () => {
          window.open(
            this.isUrlStartsWithHttps(social[SocialKeys.url])
              ? social[SocialKeys.url]
              : `https://${social[SocialKeys.url]}`,
            '_blank',
          );
        },
      });
    });
    return socialLinks;
  }

  private createWebsiteLinks(websites: Website[], defaultType: string): any[] {
    const websiteLinks = [];
    const businessWebsite = websites.find(website => website[WebsiteKeys.websiteType] === defaultType);
    const website = businessWebsite ?? websites[0];

    if (website) {
      websiteLinks.push({
        icon: 'agi-globe',
        onClick: () => {
          window.open(
            this.isUrlStartsWithHttps(website[WebsiteKeys.url])
              ? website[WebsiteKeys.url]
              : `https://${website[WebsiteKeys.url]}`,
            '_blank',
          );
        },
      });
    }

    return websiteLinks;
  }

  private isUrlStartsWithHttps = (url: string) => {
    return url.toLowerCase().startsWith('https://');
  };
}
