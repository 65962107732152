<p class="editor__data-grid--empty" *ngIf="!isEditable && !addresses?.length; else dataGridTmp">
  {{ emptyMessage }}
</p>

<ng-template #dataGridTmp>
  <dx-data-grid
    #addressesGridREf
    class="editor editor__data-grid"
    [class.editor__data-grid--view]="!isEditable"
    [dataSource]="addresses"
    [showRowLines]="isEditable"
    [showBorders]="isEditable"
    [showColumnLines]="isEditable"
    [columnAutoWidth]="true"
    [wordWrapEnabled]="true"
    [rowAlternationEnabled]="false"
    [noDataText]="emptyMessage"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onRowRemoving)="onRowRemoving($event)"
    height="100%"
  >
    <dxo-toolbar [visible]="isEditable">
      <dxi-item
        *ngIf="Entity.agencyContactInfoAddresses | hasPermission : EntityPermissionActivityKeys.create | async"
        location="after"
        widget="dxButton"
        [options]="{ icon: 'plus', onClick: showAddPopup }"
      ></dxi-item>
    </dxo-toolbar>

    <dxo-editing
      mode="popup"
      [useIcons]="true"
      [allowUpdating]="false"
      [allowDeleting]="Entity.agencyContactInfoAddresses | hasPermission : EntityPermissionActivityKeys.delete | async"
      [allowAdding]="false"
    ></dxo-editing>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-scrolling [mode]="'virtual'" [showScrollbar]="'always'"></dxo-scrolling>

    <dxi-column
      [dataField]="AddressModelKeys.address1"
      [calculateDisplayValue]="fullAddressPipe.transform"
      [caption]="isEditable ? 'Address' : ''"
    >
    </dxi-column>

    <dxi-column [dataField]="AddressModelKeys.address2" [visible]="false"></dxi-column>

    <dxi-column [dataField]="AddressModelKeys.city" [visible]="false"></dxi-column>

    <dxi-column [dataField]="AddressModelKeys.state" [visible]="false" editCellTemplate="stateEditorTmp">
      <dxo-lookup
        [dataSource]="statesLookup$ | async"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="LookupKeys.value"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AddressModelKeys.zip" [visible]="false"></dxi-column>

    <dxi-column [dataField]="AddressModelKeys.country" [visible]="false">
      <dxo-lookup [dataSource]="countries" [displayExpr]="'name'" [valueExpr]="'code'"></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AddressModelKeys.county" [visible]="false"></dxi-column>

    <dxi-column [dataField]="AddressModelKeys.addressType" [caption]="'Type'">
      <dxo-lookup
        [dataSource]="agencyAddressTypesLookup$ | async"
        [displayExpr]="LookupKeys.description"
        [valueExpr]="BaseModelKeys.dbId"
      ></dxo-lookup>
    </dxi-column>

    <dxi-column [dataField]="AddressModelKeys.isPhysicalLocation" caption="Physical Location"></dxi-column>

    <dxi-column [dataField]="AddressModelKeys.isPrimaryShipping" caption="Primary Shipping"></dxi-column>

    <dxi-column type="buttons" [visible]="isEditable">
      <dxi-button
        [visible]="Entity.agencyContactInfoAddresses | hasPermission : EntityPermissionActivityKeys.write | async"
        icon="edit"
        [onClick]="showEditPopup"
      ></dxi-button>
      <dxi-button
        name="delete"
        [visible]="
          canDeleteRow && Entity.agencyContactInfoAddresses
            | hasPermission : EntityPermissionActivityKeys.delete
            | async
        "
      ></dxi-button>
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cell of 'stateEditorTmp'">
      <ag-shr-state-select-box [value]="cell.value" (valueChange)="cell.setValue($event)"></ag-shr-state-select-box>
    </div>
  </dx-data-grid>
</ng-template>

<ag-shr-agency-address-modal
  *ngIf="isEditable"
  #agencyAddressModalRef
  [agencyAddressTypesLookup]="agencyAddressTypesLookup$ | async"
  [statesLookup]="statesLookup$ | async"
  (addressChange)="onAddressChange($event)"
></ag-shr-agency-address-modal>
