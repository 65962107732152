import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { Address, AddressModelKeys, COUNTRIES } from '@ag-common-lib/public-api';
import { getAddressKeyExpression } from 'ag-common-svc/lib/utils/address.util';
import { DxListComponent } from 'devextreme-angular';
import { InitializedEvent, SelectionChangedEvent } from 'devextreme/ui/list';
import { SelectAddressService } from './select-address.service';

@Component({
  selector: 'ag-shr-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss'],
  providers: [SelectAddressService],
})
export class SelectAddressComponent {
  @HostBinding('class') protected className = 'select-address';
  @ViewChild('listRef') listComponent: DxListComponent;

  @Input() set address(data: Partial<Address>) {
    this.selectAddressService.setFormData(data);
  }
  @Input() set addresses(data: Address[]) {
    this.selectAddressService.setAddresses(data);
  }
  @Input() isReadonly: boolean = false;
  @Input() selectorHidden: boolean = false;
  @Input() pathPrefix: string;
  @Input() selectedAddressKey: string;
  @Input() validationGroup: string;
  @Input() isCityRequired = false;
  @Input() isStateRequired: boolean = false;
  @Input() isZipRequired: boolean = false;
  @Input() isAddress1Required: boolean = true;

  protected addressesDataSource$ = this.selectAddressService.agentAddressesDataSource$;
  protected formData$ = this.selectAddressService.formData$;
  protected selectedAddressKeys: string[];

  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly countries = COUNTRIES;

  constructor(private selectAddressService: SelectAddressService) {
    this.selectAddressService.selectedAddressKeys$.subscribe(selectedAddressKeys => {
      this.listComponent?.instance?.off('selectionChanged');
      this.selectedAddressKeys = selectedAddressKeys;

      setTimeout(() => {
        this.listComponent?.instance?.on('selectionChanged', this.onSelectionChanged);
      }, 0);
    });
  }

  protected onInitialized = (e: InitializedEvent) => {
    if (this.onSelectionChanged) {
      e.component.instance().on('selectionChanged', this.onSelectionChanged);
    }
  };

  protected onSelectionChanged = (e: SelectionChangedEvent) => {
    const address = e.addedItems[0];
    this.selectAddressService.updateAddress(address, undefined);
  };

  protected addCopyingAddress(e, addressToCopy: Address): void {
    this.listComponent.instance.off('selectionChanged');
    this.selectedAddressKeys = [this.addressKeyExpression({})];
    const address = Object.assign({}, addressToCopy, {
      [AddressModelKeys.isPrimaryShipping]: false,
      [AddressModelKeys.isPrimaryBilling]: false,
    });
    this.selectAddressService.updateAddress(address, undefined);

    setTimeout(() => {
      this.listComponent.instance.on('selectionChanged', this.onSelectionChanged);
    }, 0);

    e.event.stopPropagation();
  }

  protected addressKeyExpression = getAddressKeyExpression;
}
