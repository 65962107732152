<dx-select-box
  [name]="name"
  [dataSource]="statesLookup$ | async"
  [(value)]="value"
  (valueChange)="valueChange.emit($event)"
  [readOnly]="readOnly"
  [disabled]="disabled"
  [label]="label"
  [labelMode]="labelMode"
  [displayExpr]="'description'"
  [valueExpr]="'value'"
  [searchExpr]="['value', 'description']"
  [searchEnabled]="true"
  [dropDownOptions]="{ width: 230 }"
  fieldTemplate="field"
>
  <div *dxTemplate="let data of 'field'">
    <div>
      <dx-text-box
        text="{{ data?.value ?? '' }}"
        [inputAttr]="{
          autocomplete: 'disabled'
        }"
      >
      </dx-text-box>
    </div>
  </div>

  <dx-validator [validationGroup]="validationGroup">
    <dxi-validation-rule *ngIf="isRequired" type="required" [message]="Messages.REQUIRED_TEXT"> </dxi-validation-rule>
  </dx-validator>
</dx-select-box>
