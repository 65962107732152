import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { StateSelectBoxModule } from '../state-select-box/state-select-box.module';
import { AddressFormComponent } from './address-form.component';

@NgModule({
  declarations: [AddressFormComponent],
  imports: [CommonModule, SharedModule, StateSelectBoxModule],
  exports: [AddressFormComponent],
})
export class AddressFormModule {}
