import { FirebaseApp } from '@firebase/app';
import { BaseModel, BaseModelKeys } from '@ag-common-lib/public-api';
import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  FieldPath,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  writeBatch,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  SnapshotOptions,
  Timestamp,
  where,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  orderBy,
  limit,
} from 'firebase/firestore';
import { fromUnixTime, isDate, isValid, toDate } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth, getAuth } from 'firebase/auth';
import * as uuid from 'uuid';

export interface FetchOptions {
  includeRef?: boolean;
  sortField?: string;
}

const localeCompareOptions: Intl.CollatorOptions = {
  numeric: true,
  sensitivity: 'base',
  ignorePunctuation: true,
};
export class CommonFireStoreDao<T extends BaseModel> {
  readonly db: Firestore;
  private readonly auth: Auth;
  private fromFirestore: (documentData: DocumentData) => T = null;
  private toFirestore: (item: T) => DocumentData | T = null;

  private readonly updatesToSkip = new Set();

  constructor(
    fireBaseApp: FirebaseApp,
    fromFirestore: (data: Partial<T>) => T = null,
    toFirestore: (item: T) => DocumentData | T = null,
  ) {
    this.db = initializeFirestore(fireBaseApp, {
      experimentalForceLongPolling: true,
      ignoreUndefinedProperties: true,
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    });
    this.auth = getAuth(fireBaseApp);
    this.fromFirestore = fromFirestore ?? null;
    this.toFirestore = toFirestore ?? null;
  }

  async createWithId(value: T, uid: string, table: string): Promise<T> {
    const ref = doc(this.db, table, uid).withConverter({
      fromFirestore: null,
      toFirestore: (item: T): DocumentData => {
        return Object.assign(this.toFirestore ? this.toFirestore(item) : item, {
          [BaseModelKeys.dbId]: uid,
          [BaseModelKeys.actionId]: uuid.v4(),
          [BaseModelKeys.createdDate]: new Date(),
          [BaseModelKeys.createdBy]: this.auth?.currentUser?.uid ?? null,
        });
      },
    });

    await setDoc(ref, value);

    return this.getById(table, uid);
  }

  async batchCreate(values: T[], table: string): Promise<any> {
    const currentUser = this.auth?.currentUser;
    const idTokenResult = !!currentUser ? await currentUser?.getIdTokenResult() : null;
    const loggedInAgentDbId = idTokenResult?.claims?.agentDbId;
    const collectionRef = collection(this.db, table).withConverter({
      fromFirestore: null,
      toFirestore: (item: T, ...rest): DocumentData => {
        return Object.assign(
          {
            [BaseModelKeys.actionId]: uuid.v4(),
            [BaseModelKeys.createdDate]: new Date(),
            [BaseModelKeys.createdBy]: currentUser?.uid ?? null,
            [BaseModelKeys.createdByEmail]: currentUser?.email ?? item?.created_by ?? null,
            [BaseModelKeys.createdByAgentDbId]: loggedInAgentDbId ?? null,
          },
          this.toFirestore ? this.toFirestore(item) : item,
        );
      },
    });
    const batchSize = 500;
    const batchCount = Math.ceil(values.length / batchSize);

    for (let i = 0; i < batchCount; i++) {
      const startIndex = i * batchSize;
      const endIndex = (i + 1) * batchSize;
      const batchValues = values.slice(startIndex, endIndex);
      const batch = writeBatch(this.db);

      for (let value of batchValues) {
        const docRef = doc(collectionRef);

        batch.set(docRef, Object.assign({ [BaseModelKeys.dbId]: docRef.id }, value));
      }

      await batch.commit();
    }
  }

  async create(value: T, table: string): Promise<T> {
    const currentUser = this.auth?.currentUser;
    const idTokenResult = !!currentUser ? await currentUser?.getIdTokenResult() : null;
    const loggedInAgentDbId = idTokenResult?.claims?.agentDbId;

    const ref = collection(this.db, table).withConverter({
      fromFirestore: null,
      toFirestore: (item: T, ...rest): DocumentData => {
        return Object.assign(
          {
            [BaseModelKeys.actionId]: uuid.v4(),
            [BaseModelKeys.createdDate]: new Date(),
            [BaseModelKeys.createdBy]: currentUser?.uid ?? null,
            [BaseModelKeys.createdByEmail]: currentUser?.email ?? item?.created_by ?? null,
            [BaseModelKeys.createdByAgentDbId]: loggedInAgentDbId ?? null,
          },
          this.toFirestore ? this.toFirestore(item) : item,
        );
      },
    });

    const docRef = doc(ref);

    await setDoc(docRef, Object.assign({ [BaseModelKeys.dbId]: docRef.id }, value), { merge: true });

    return this.getById(table, docRef.id);
  }

  getCollectionGroupSnapshot(
    table,
    queries: QueryParam[] = [],
    updateSnapshot?: boolean,
  ): Observable<QuerySnapshot<T>> {
    return new Observable(observer => {
      const queryConstraints: QueryConstraint[] = queries.map(query =>
        where(query.field, query.operation, query.value),
      );
      const collectionRef = collectionGroup(this.db, table).withConverter({
        toFirestore: this.normalizeUpdateRequest,
        fromFirestore: this.convertResponse,
      });
      const collectionQuery = query(collectionRef, ...queryConstraints);

      onSnapshot(
        collectionQuery,
        { includeMetadataChanges: true },
        snapshot => {
          if (snapshot.metadata.fromCache) {
            return;
          }
          if (!snapshot.metadata.fromCache || updateSnapshot) {
            observer.next(snapshot);
          }
        },
        error => {
          debugger;
          // Handle your error here. It will not log in the console anymore,
          // and the listener has already been automatically detached at this point.
        },
      );
    });
  }

  getCollectionSnapshot(table, queries: QueryParam[] = []): Observable<QuerySnapshot<T[]>> {
    return new Observable(observer => {
      const queryConstraints: QueryConstraint[] = queries.map(query =>
        where(query.field, query.operation, query.value),
      );

      const collectionRef = collection(this.db, table).withConverter({
        toFirestore: this.normalizeUpdateRequest,
        fromFirestore: this.convertResponse,
      });
      const collectionQuery = query(collectionRef, ...queryConstraints);

      onSnapshot(
        collectionQuery,
        { includeMetadataChanges: true },
        snapshot => {
          if (snapshot.metadata.fromCache && !!navigator.onLine) {
            return;
          }

          if (!this.updatesToSkip.size) {
            observer.next(snapshot);
            return;
          }

          const docChanges = snapshot.docChanges();

          const skip = docChanges.every(docChange => {
            return this.updatesToSkip.has(docChange.doc.id);
          });

          if (!skip) {
            observer.next(snapshot);
            return;
          }

          docChanges.forEach(docChange => {
            return this.updatesToSkip.delete(docChange.doc.id);
          });
        },
        error => {
          // Handle your error here. It will not log in the console anymore,
          // and the listener has already been automatically detached at this point.
        },
      );
    });
  }

  getList(table, queries: QueryParam[] = [], fetchOptions?: FetchOptions): Observable<T[]> {
    return this.getCollectionSnapshot(table, queries).pipe(
      map((collectionSnapshot: any) => {
        const items = collectionSnapshot.docs.map(document => {
          if (!document.exists()) {
            return null;
          }

          const data = document.data();
          if (fetchOptions?.includeRef ?? false) {
            Object.assign(data, { [BaseModelKeys.firebaseRef]: document.ref });
          }

          return data;
        });

        if (fetchOptions?.sortField) {
          items.sort((left, right) =>
            String(left[fetchOptions?.sortField]).localeCompare(
              String(right[fetchOptions?.sortField]),
              'en',
              localeCompareOptions,
            ),
          );
        }

        return items;
      }),
    );
  }

  async getAll(table: string, sortField?: string): Promise<T[]> {
    const collectionRef = collection(this.db, table).withConverter({
      toFirestore: this.normalizeUpdateRequest,
      fromFirestore: this.convertResponse,
    });

    const querySnapshot = await getDocs(collectionRef);

    const docsData = querySnapshot.docs.map(item => (item.exists() ? item.data() : null));

    if (sortField) {
      docsData.sort((left, right) =>
        String(left[sortField]).localeCompare(String(right[sortField]), 'en', localeCompareOptions),
      );
    }

    return docsData;
  }

  async getMostRecentOrderBy(table: string, order: string): Promise<T[]> {
    const ref = collection(this.db, table).withConverter({
      toFirestore: this.normalizeUpdateRequest,
      fromFirestore: this.convertResponse,
    });

    const q = query(ref, orderBy(order, 'desc'), limit(1));

    const snap = await getDocs(q);

    const docsData = snap.docs.map(item => (item.exists() ? item.data() : null));

    if (order) {
      docsData.sort((left, right) =>
        String(left[order]).localeCompare(String(right[order]), 'en', localeCompareOptions),
      );
    }

    return docsData;
  }

  getDocReference(table: string, id: string) {
    if (!id) {
      return null;
    }
    return doc(this.db, table, id).withConverter({
      toFirestore: this.normalizeUpdateRequest,
      fromFirestore: this.convertResponse,
    });
  }

  getDocument(table: string, id: string): Observable<DocumentSnapshot<T>> {
    return new Observable(observer => {
      const ref = this.getDocReference(table, id);

      onSnapshot(
        ref,
        { includeMetadataChanges: true },
        snapshot => {
          if (!snapshot.metadata.fromCache) {
            observer.next(snapshot);
          }
        },
        error => {
          // Handle your error here. It will not log in the console anymore,
          // and the listener has already been automatically detached at this point.
        },
      );
    });
  }

  async getById(table: string, id: string): Promise<T> {
    const ref = this.getDocReference(table, id);

    if (!ref) {
      return null;
    }

    const snap = await getDoc(ref);
    console.log('getById snap metadata', table, id, snap.metadata);

    const isExist = snap?.exists();

    return isExist ? (snap?.data() as T) : null;
  }

  async delete(id: string, table: string): Promise<void> {
    const ref = doc(this.db, table, id);
    const currentUser = this.auth?.currentUser;
    const idTokenResult = await this.auth.currentUser.getIdTokenResult();
    const loggedInAgentDbId = idTokenResult?.claims?.agentDbId;

    await setDoc(
      ref,
      {
        [BaseModelKeys.actionId]: uuid.v4(),
        [BaseModelKeys.deletedDate]: new Date(),
        [BaseModelKeys.deletedBy]: currentUser?.uid ?? null,
        [BaseModelKeys.deletedByEmail]: currentUser?.email ?? null,
        [BaseModelKeys.deletedByAgentDbId]: loggedInAgentDbId ?? null,
      },
      { merge: true },
    );

    return deleteDoc(ref);
  }

  async updateFields(value, id: string, table: string, skipListUpdate = false): Promise<T> {
    const currentUser = this.auth?.currentUser;
    const idTokenResult = await this.auth.currentUser.getIdTokenResult();
    const loggedInAgentDbId = idTokenResult?.claims?.agentDbId;

    const ref = doc(this.db, table, id).withConverter({
      fromFirestore: null,
      toFirestore: (item: T): DocumentData => {
        const data = Object.assign(this.toFirestore ? this.toFirestore(item) : item, {
          [BaseModelKeys.actionId]: uuid.v4(),
          [BaseModelKeys.updatedDate]: new Date(),
          [BaseModelKeys.updatedBy]: currentUser?.uid ?? null,
          [BaseModelKeys.updatedByEmail]: currentUser?.email ?? null,
          [BaseModelKeys.updatedByAgentDbId]: loggedInAgentDbId ?? null,
        });

        return data;
      },
    });

    if (skipListUpdate) {
      this.updatesToSkip.add(ref.id);
    }
    console.log('value', value);

    await setDoc(ref, value, { merge: true });

    return this.getById(table, id);
  }

  /**
   * @deprecated Use updateFields instead
   */
  public async update(value, id: string, table: string): Promise<T> {
    const ref = doc(this.db, table, id).withConverter({
      fromFirestore: null,
      toFirestore: (item: T): DocumentData => {
        return Object.assign(item, {
          [BaseModelKeys.updatedDate]: new Date(),
          [BaseModelKeys.updatedBy]: this.auth?.currentUser?.uid ?? null,
        });
      },
    });

    await setDoc(ref, value);

    return this.getById(table, id);
  }

  public async getAllByQValue(table: string, queries: QueryParam[], sortField?: string): Promise<T[]> {
    const queryConstraints: QueryConstraint[] = queries.map(query => where(query.field, query.operation, query.value));

    const ref = collection(this.db, table).withConverter({
      toFirestore: this.normalizeUpdateRequest,
      fromFirestore: this.convertResponse,
    });

    const documentQuery = query(ref, ...queryConstraints);

    const snap = await getDocs(documentQuery);

    const docsData = snap.docs.map(item => (item.exists() ? item.data() : null));

    if (sortField) {
      docsData.sort((left, right) =>
        String(left[sortField]).localeCompare(String(right[sortField]), 'en', localeCompareOptions),
      );
    }

    return docsData;
  }

  convertResponse = (snapshot: QueryDocumentSnapshot, options: SnapshotOptions): any => {
    const isExist = snapshot.exists();
    if (!isExist) {
      return;
    }
    const data = snapshot.data(options);

    const normalizedData = Object.assign({}, data, {
      dbId: snapshot.id,
      [BaseModelKeys.createdDate]: this.dateFromTimestamp(data[BaseModelKeys.createdDate]),
      [BaseModelKeys.updatedDate]: this.dateFromTimestamp(data[BaseModelKeys.updatedDate]),
      [BaseModelKeys.deletedDate]: this.dateFromTimestamp(data[BaseModelKeys.deletedDate]),
    });

    return this.fromFirestore ? this.fromFirestore(normalizedData) : normalizedData;
  };

  private normalizeUpdateRequest = (item: T): DocumentData => {
    const data = Object.assign(this.toFirestore ? this.toFirestore(item) : item, {
      [BaseModelKeys.updatedDate]: new Date(),
      [BaseModelKeys.updatedBy]: this.auth?.currentUser?.uid ?? null,
    });

    return data;
  };

  private dateFromTimestamp = (item: Timestamp) => {
    if (!item) {
      return null;
    }

    if (isDate(item)) {
      return item;
    }

    let normalizedDate;

    if (item?.seconds) {
      normalizedDate = fromUnixTime(item.seconds);
    }

    return isValid(toDate(normalizedDate)) ? normalizedDate : null;
  };
}

export enum WhereFilterOperandKeys {
  less = '<',
  lessOrEqual = '<=',
  equal = '==',
  notEqual = '!=',
  more = '>',
  moreOrEqual = '>=',
  arrayContains = 'array-contains',
  in = 'in',
  arrayContainsAny = 'array-contains-any',
  notIn = 'not-in',
}

export class QueryParam {
  constructor(field: string | FieldPath, operation: WhereFilterOperandKeys, value: any) {
    this.field = field;
    this.operation = operation;
    this.value = value;
  }
  field: string | FieldPath;
  value: any;
  operation: WhereFilterOperandKeys;
}
