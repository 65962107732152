import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Address, Entity, EntityPermissionActivityKeys, EntityPermissionModelKeys } from 'ag-common-lib/public-api';
import { ModalWindowComponent } from '../../../modal-window/modal-window.component';
import { AgencyService } from '../../../../services/agency.service';
import { AgencyAddressesService } from '../addresses/agency-addresses.service';

@Component({
  selector: 'ag-shr-agency-addresses',
  templateUrl: './agency-addresses.component.html',
  styleUrls: ['./agency-addresses.component.scss'],
  providers: [AgencyService],
})
export class AgencyAddressesComponent {
  @ViewChild('agencyAddressesModalRef', { static: true }) agencyAddressesModalComponent: ModalWindowComponent;
  @Input() agencyId: string;
  @Input() addresses: Address[] = [];
  @Output() addressesChange = new EventEmitter<Address[]>();

  inProgress$ = this.agencyAddressesService.inProgress$;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(private agencyAddressesService: AgencyAddressesService) {}

  onAddressesChange(addresses: Address[]): void {
    this.addressesChange.emit(addresses);
  }

  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
