import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ButtonWithIndicatorModule } from '../../../button-with-indicator/button-with-indicator.module';
import { ModalWindowModule } from '../../../modal-window/modal-window.module';
import { StateSelectBoxModule } from '../../../state-select-box/state-select-box.module';
import { AgencyAddressesComponent } from './agency-addresses.component';
import { AddressesGridComponent } from './addresses-grid/addresses-grid.component';
import { AgEditorToolbarModule } from '../../../ag-editor-toolbar/ag-editor-toolbar.module';
import { AgencyAddressesService } from './agency-addresses.service';
import { AgencyAddressModalComponent } from './address-modal/agency-address-modal.component';

@NgModule({
  imports: [SharedModule, ModalWindowModule, ButtonWithIndicatorModule, StateSelectBoxModule, AgEditorToolbarModule],
  declarations: [AgencyAddressesComponent, AddressesGridComponent, AgencyAddressModalComponent],
  exports: [AgencyAddressesComponent, AddressesGridComponent, AgencyAddressModalComponent],
  providers: [AgencyAddressesService],
})
export class AgencyAddressesModule {}
