export enum Entity {
  //** CRM: **//
  agentCRMParent = 'agentCRMParent',
  agencyParent = 'agencyParent',
  registrantsParent = 'registrantsParent',
  prospectParent = 'prospectParent',
  registrationsConferencesParent = 'registrationsConferencesParent',
  campaignTaskBoardParent = 'campaignTaskBoardParent',
  campaignTaskGridParent = 'campaignTaskGridParent',
  reportStatusReportParent = 'reportStatusReportParent',
  reportEmailReportParent = 'reportEmailReportParent',
  notificationsCRMParent = 'notificationsCRMParent',
  carrierCrm = 'carrierCrm',

  // agentCRM = 'agentCRM', // removed
  agency = 'agency',
  prospect = 'prospect',
  registrants = 'registrants',

  emailDistributionLists = 'emailDistributionLists',
  emailTemplatesLists = 'emailTemplatesLists',

  registrationsConferences = 'registrationsConferences',
  registrationsWebinars = 'registrationsWebinars',
  registrationsContests = 'registrationsContests',

  campaignServices = 'campaignServices',
  campaignTaskBoard = 'campaignTaskBoard',
  campaignTaskGrid = 'campaignTaskGrid',

  reportStatusReport = 'reportStatusReport',
  reportRegistrationReport = 'reportRegistrationReport',
  reportEmailReport = 'reportEmailReport',
  reportChristmasCardList = 'reportChristmasCardList',

  administrationTaskListManager = 'administrationTaskListManager',
  administrationImportRulesManager = 'administrationImportRulesManager',
  administrationLookupsManager = 'administrationLookupsManager',
  administrationErrorsManager = 'administrationErrorsManager',
  administrationLogsManager = 'administrationLogsManager',
  administrationReportsManager = 'administrationReportsManager',
  administrationUserManager = 'administrationUserManager',
  administrationRoleManager = 'administrationRoleManager',
  administrationGoogleAuthManager = 'administrationGoogleAuthManager',
  administrationAuthStats = 'administrationAuthStats',
  administrationMergeTool = 'administrationMergeTool',

  // Push Notifications
  notificationsCRM = 'notificationsCRM',
  notificationsPortal = 'notificationsPortal',
  notificationsTemplates = 'notificationsTemplates',
  notificationsChannels = 'notificationsChannels',
  notificationsScheduler = 'notificationsScheduler',
  notificationsBroadcastDelivery = 'notificationsBroadcastDelivery',

  // SubEntity:
  agentSalesGoal = 'agentSalesGoal',
  agentProspectStatusActivity = 'agentProspectStatusActivity',
  agentGenerateAgentId = 'agentGenerateAgentId',

  conferenceParent = 'conferenceParent',
  conference = 'conference',
  conferenceRegistrantsParent = 'conferenceRegistrantsParent',
  conferenceRegistrants = 'conferenceRegistrants',
  conferenceAttendeeDetails = 'conferenceAttendeeDetails',
  conferenceTransactions = 'conferenceTransactions',
  conferenceExcursionStatistic = 'conferenceExcursionStatistic',
  conferenceChangeLogs = 'conferenceChangeLogs',
  conferenceListAdmin = 'conferenceListAdmin',
  conferenceList = 'conferenceList',

  eventAttendeesOverallExcelReport = 'eventAttendeesOverallExcelReport',
  eventAttendeesCustomReports = 'eventAttendeesCustomReports',
  eventAttendeesMassMailing = 'eventAttendeesMassMailing',
  eventAttendeesSendEmailToQualifier = 'eventAttendeesSendEmailToQualifier',

  conferencePrimaryInfo = 'conferencePrimaryInfo',
  conferenceEmailSenderConfiguration = 'conferenceEmailSenderConfiguration',
  conferenceWelcomeInfo = 'conferenceWelcomeInfo',
  conferenceStepsConfiguration = 'conferenceStepsConfiguration',
  conferenceHotelInfo = 'conferenceHotelInfo',
  conferenceGuestsInfo = 'conferenceGuestsInfo',
  conferenceExcursionsInfo = 'conferenceExcursionsInfo',
  conferencePermissionsInfo = 'conferencePermissionsInfo',

  agentDisposition = 'agentDisposition',
  assignOwnerRegistrants = 'assignOwnerRegistrants',
  assignAgentIdRegistrants = 'assignAgentIdRegistrants',
  assignMGA = 'assignMGA',
  prospectStatusActivity = 'prospectStatusActivity',
  prospectWrapUp = 'prospectWrapUp',
  assignOwnerCampaign = 'assignOwnerCampaign',
  campaignsInfo = 'campaignsInfo',
  campaignsTaskIssues = 'campaignsTaskIssues',
  campaignsTaskLogs = 'campaignsTaskLogs',
  statusAppointmentScheduler = 'statusAppointmentScheduler',
  emailAppointmentScheduler = 'emailAppointmentScheduler',
  agencyManagementParent = 'agencyManagementParent',
  agencyManagement = 'agencyManagement',
  agentManagementParent = 'agentManagementParent',
  agentManagement = 'agentManagement',
  logAgencyNote = 'logAgencyNote',
  logAgentActivity = 'logAgentActivity',
  logAgentNote = 'logAgentNote',
  logAgencyMeeting = 'logAgencyMeeting',
  logAgentMeeting = 'logAgentMeeting',
  logAgencyCall = 'logAgencyCall',
  logAgentCall = 'logAgentCall',
  logAgentEmail = 'logAgentEmail',
  logAgencyEmail = 'logAgencyEmail',
  logAddAgentTask = 'logAddAgentTask',
  logAddAgencyTask = 'logAddAgencyTask',

  agentEditPicture = 'agentEditPicture',
  agencyEditPicture = 'agencyEditPicture',

  agencyAdminister = 'agencyAdminister',
  agencyMedia = 'agencyMedia',
  agencyInformation = 'agencyInformation',
  agencyAgencyRelationshipWithAllianceGroup = 'agencyAgencyRelationshipWithAllianceGroup',
  agencyAgencyInfoCarriers = 'agencyAgencyInfoCarriers',
  agencyAgencyInfoStatusActivity = 'agencyAgencyInfoStatusActivity',
  agencyAgencyInfoStatesLicensedIn = 'agencyAgencyInfoStatesLicensedIn',
  agencyContactInfoAddresses = 'agencyContactInfoAddresses',
  agencyContactInfoSocials = 'agencyContactInfoSocials',
  agencyContactInfoOfficeEmailAddresses = 'agencyContactInfoOfficeEmailAddresses',
  agencyContactInfoOfficePhoneNumbers = 'agencyContactInfoOfficePhoneNumbers',
  agencyContactInfoWebsites = 'agencyContactInfoWebsites',

  agentAdminister = 'agentAdminister',
  agentInformation = 'agentInformation',
  agentMedia = 'agentMedia',
  agentContactInfoAddresses = 'agentContactInfoAddresses',
  agentContactInfoSocials = 'agentContactInfoSocials',
  agentContactInfoEmailAddresses = 'agentContactInfoEmailAddresses',
  agentContactInfoPhoneNumbers = 'agentContactInfoPhoneNumbers',
  agentContactInfoWebsites = 'agentContactInfoWebsites',

  agentPersonalInformation = 'agentPersonalInformation',
  agentPersonalInfoAssociations = 'agentPersonalInfoAssociations',
  agentPersonalInfoDietaryConsiderations = 'agentPersonalInfoDietaryConsiderations',
  agentPersonalInfoSizesForFunStuff = 'agentPersonalInfoSizesForFunStuff',

  agentAgentInfoAgentPortal = 'agentAgentInfoAgentPortal',
  agentAgentInfoStatusActivity = 'agentAgentInfoStatusActivity',
  agentAgentInfoMga = 'agentAgentInfoMga',
  agentAgentInfoNeedToKnow = 'agentAgentInfoNeedToKnow',
  agentAgentInfoDepartmentOfInsuranceInfo = 'agentAgentInfoDepartmentOfInsuranceInfo',
  agentAgentInfoStatesLicensedIn = 'agentAgentInfoStatesLicensedIn',
  agentAgentInfoCarriers = 'agentAgentInfoCarriers',
  agentCampaignWizard = 'agentCampaignWizard',

  agentPermissions = 'agentPermissions',

  //** PORTAL: **//
  agentPortalParent = 'agentPortalParent',
  adminToolsMenuParent = 'adminToolsMenuParent',
  campaignWizardParent = 'campaignWizardParent',
  conferenceWizardParent = 'conferenceWizardParent',

  agentPortal = 'agentPortal',
  agentPortalEditPicture = 'agentPortalEditPicture',
  agentPortalSalesGoal = 'agentPortalSalesGoal',
  agentPortalContactInfo = 'agentPortalContactInfo',
  agentPortalPersonalInfo = 'agentPortalPersonalInfo',
  agentPortalPermissions = 'agentPortalPermissions',
  agentPortalMedia = 'agentPortalMedia',
  agentPortalInformation = 'agentPortalInformation',
  // Personal Menu
  campaignWizard = 'campaignWizard',
  conferenceWizard = 'conferenceWizard',
  ordersEditor = 'ordersEditor',
  contestsEditor = 'contestsEditor',
  // Admin Tools Menu
  adminToolsMenu = 'adminToolsMenu',

  settingsGoals = 'settingsGoals',
  settingsErrors = 'settingsErrors',

  contentAnnouncements = 'contentAnnouncements',
  contentCalendar = 'contentCalendar',
  contentCards = 'contentCards',
  contentForms = 'contentForms',
  contentMacros = 'contentMacros',
  contentMenu = 'contentMenu',
  contentPages = 'contentPages',
  contentSplashScreens = 'contentSplashScreens',
  contentVideos = 'contentVideos',

  storeOrders = 'storeOrders',
  storeProducts = 'storeProducts',
  storeCoupons = 'storeCoupons',

  conferenceWizardRSVP = 'conferenceWizardRSVP',
  conferenceWizardPersonalInfo = 'conferenceWizardPersonalInfo',
  conferenceWizardExcursions = 'conferenceWizardExcursions',
  conferenceWizardHotelReservation = 'conferenceWizardHotelReservation',
  conferenceWizardGuestRegistration = 'conferenceWizardGuestRegistration',
  conferenceWizardFlightInformation = 'conferenceWizardFlightInformation',
  conferenceWizardConfirmation = 'conferenceWizardConfirmation',
  conferenceWizardPayment = 'conferenceWizardPayment',
  conferenceWizardRegistrationSummary = 'conferenceWizardRegistrationSummary',

  campaignWizardPickYourWebsite = 'campaignWizardPickYourWebsite',
  campaignWizardAddYourContactInfo = 'campaignWizardAddYourContactInfo',
  campaignWizardAddYourBio = 'campaignWizardAddYourBio',
  campaignWizardAddSocialMedia = 'campaignWizardAddSocialMedia',
  campaignWizardAddYourLogoHeadshot = 'campaignWizardAddYourLogoHeadshot',
  campaignWizardAddAppointment = 'campaignWizardAddAppointment',
  campaignWizardAddExternalLinks = 'campaignWizardAddExternalLinks',
  campaignWizardSummary = 'campaignWizardSummary',
}

export const entityNameList = Object.keys(Entity);

export const administrationMenuEntityList: Entity[] = [
  Entity.administrationRoleManager,
  Entity.administrationErrorsManager,
  Entity.administrationLookupsManager,
  Entity.administrationReportsManager,
  Entity.administrationLogsManager,
  Entity.administrationTaskListManager,
  Entity.administrationImportRulesManager,
  Entity.agentSalesGoal,
  Entity.administrationGoogleAuthManager,
  Entity.administrationUserManager,
  Entity.administrationAuthStats,
  Entity.administrationMergeTool
];

export const campaignMenuEntityList: Entity[] = [
  Entity.campaignServices,
  Entity.campaignTaskBoard,
  Entity.campaignTaskGrid,
];
