import { Agent, AgentCampaignsKeys, AgentKeys, StateLicense } from '@ag-common-lib/public-api';
import { dateFromTimestamp, localDateTimeFromTimestamp } from '../../utils/date-from-timestamp';
import { Timestamp } from 'firebase/firestore';

export const getAgentFullName = (agent: Agent) => {
  return [agent?.[AgentKeys.p_agent_first_name], agent?.[AgentKeys.p_agent_last_name]].filter(Boolean).join(' ');
};

export const agentFromFirestore = (data): Agent => {
  data[AgentKeys.approve_deny_reasons]?.forEach(reason => {
    reason.created_date = dateFromTimestamp(reason.created_date as Timestamp);
  });

  const fullName = getAgentFullName(data);

  return Object.assign({}, data, {
    [AgentKeys.p_agent_name]: fullName,
    [AgentKeys.registrant_review_level_update_date]: dateFromTimestamp(
      data?.registrant_review_level_update_date as Timestamp,
    ),
    [AgentKeys.prospect_status_update_date]: dateFromTimestamp(data?.prospect_status_update_date as Timestamp),
    [AgentKeys.campaigns]: Object.assign({}, data[AgentKeys.campaigns], {
      [AgentCampaignsKeys.userSince]: dateFromTimestamp(data[AgentKeys.campaigns]?.userSince as Timestamp),
      [AgentCampaignsKeys.stoppedCampaigns]: dateFromTimestamp(
        data[AgentKeys.campaigns]?.stoppedCampaigns as Timestamp,
      ),
    }),
    [AgentKeys.dob]: localDateTimeFromTimestamp(data?.dob as Timestamp),
    [AgentKeys.christmas_card]: data?.christmas_card ?? false,
    [AgentKeys.conference_poster]: data?.conference_poster ?? false,
    [AgentKeys.first_login_date]: dateFromTimestamp(data?.first_login_date as Timestamp),
    [AgentKeys.last_login_date]: dateFromTimestamp(data?.last_login_date as Timestamp),
    [AgentKeys.registrationDate]: dateFromTimestamp(data?.registrationDate as Timestamp),
    [AgentKeys.personal_goals]: Array.isArray(data?.personal_goals) ? data?.personal_goals : [],
    [AgentKeys.conference_goals]: Array.isArray(data?.conference_goals) ? data?.conference_goals : [],
    [AgentKeys.roles]: Array.isArray(data?.roles) ? data?.roles : [],
    [AgentKeys.state_licenses]: Array.isArray(data?.[AgentKeys.state_licenses])
      ? data?.state_licenses?.map((license: StateLicense) => {
          const { expiration, ...rest } = license ?? {};

          return Object.assign({}, rest, {
            expiration: dateFromTimestamp(expiration),
          });
        })
      : [],
  });
};
