<dx-list
  #listRef
  [items]="addressesDataSource$ | async"
  [selectionMode]="'single'"
  [showSelectionControls]="true"
  [keyExpr]="addressKeyExpression"
  (onInitialized)="onInitialized($event)"
  [(selectedItemKeys)]="selectedAddressKeys"
  [disabled]="isReadonly"
  [visible]="!selectorHidden"
>
  <div *dxTemplate="let address of 'item'">
    <ng-container *ngIf="address?.address1; else useNewAddress">
      <div class="text-wrap">{{ address | fullAddress }}</div>

      <i *ngIf="address?.is_primary_shipping" class="dx-icon-agi-shipping-address select-address__list-icon"></i>
      <i *ngIf="address?.is_primary_billing" class="dx-icon-agi-billing-address select-address__list-icon"></i>

      <dx-button
        class="clipboard-button"
        icon="copy"
        hint="Copy And Edit"
        stylingMode="text"
        (onClick)="addCopyingAddress($event, address)"
      >
      </dx-button>
    </ng-container>

    <ng-template #useNewAddress>
      <div>Add new Address</div>
    </ng-template>
  </div>
</dx-list>

<ag-shr-address-form
  class="mt-2"
  [address]="formData$ | async"
  [pathPrefix]="pathPrefix"
  [isAddress1Required]="isAddress1Required"
  [isCityRequired]="isCityRequired"
  [isStateRequired]="isStateRequired"
  [isCountryVisible]="false"
  [isCountyVisible]="false"
  [isZipRequired]="isZipRequired"
  [validationGroup]="validationGroup"
  [isReadonly]="isReadonly || !(selectedAddressKeys | isNewAddressSelected)"
></ag-shr-address-form>
