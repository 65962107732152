import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  BaseModelKeys,
  Constants,
  Entity,
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  LookupKeys,
  Messages,
  PhoneNumber,
  PhoneNumberKeys,
  PhoneNumberType,
  SupportType,
} from 'ag-common-lib/public-api';
import { DxDataGridComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { LookupsService } from '../../../../../services/lookups.service';
import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { InitNewRowEvent } from 'devextreme/ui/data_grid';
import { firstValueFrom } from 'rxjs';
import { OfficePhoneNumberService } from '../office-phone-number.service';

@UntilDestroy()
@Component({
  selector: 'ag-shr-agency-office-phone-numbers-grid',
  templateUrl: './office-phone-number-grid.component.html',
  styleUrls: ['./office-phone-number-grid.component.scss'],
})
export class OfficePhoneNumberGridComponent {
  @ViewChild('phoneNumbersGridREf', { static: false }) phoneNumbersGridComponent: DxDataGridComponent;
  @Input() phoneNumbers: PhoneNumber[] = [];
  @Input() isEditable: boolean = true;
  @Input() agencyId: string;
  @Output() phoneNumbersChange = new EventEmitter<PhoneNumber[]>();

  supportTypesLookup$ = this.lookupsService.supportTypesLookup$;
  agencyPhoneTypes$ = this.lookupsService.agencyPhoneTypes$;

  inProgress$ = this.officePhoneNumberService.inProgress$;
  protected readonly Messages = Messages;
  protected readonly totalPhoneDigits: number = Constants.TOTAL_PHONE_DIGITS;
  protected readonly phoneMask: string = Constants.PHONE_MASK;
  protected readonly emptyMessage = 'No Phone Numbers Currently Exist';
  protected readonly PhoneNumberKeys = PhoneNumberKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly BaseModelKeys = BaseModelKeys;
  protected EntityPermissionActivityKeys = EntityPermissionActivityKeys;

  constructor(
    private toastrService: ToastrService,
    private lookupsService: LookupsService,
    private officePhoneNumberService: OfficePhoneNumberService,
  ) {}

  onEditorPreparing = e => {
    if (e.parentType !== 'dataRow') {
      return;
    }

    if (e.dataField === 'is_primary') {
      e.editorOptions.disabled = e.row.data.is_primary;
    }
  };

  onInitNewRow = (e: InitNewRowEvent) => {
    e.data.is_primary = !this.phoneNumbers?.length;

    const mainOfficeLookup$ = firstValueFrom(
      this.supportTypesLookup$.pipe(map(lookups => lookups?.find(lookup => lookup.value === SupportType.MainOffice))),
    );
    const mobilePhoneTypeLookup$ = firstValueFrom(
      this.agencyPhoneTypes$.pipe(map(lookups => lookups?.find(lookup => lookup.value === PhoneNumberType.Mobile))),
    );

    e.promise = Promise.all([mainOfficeLookup$, mobilePhoneTypeLookup$]).then(
      ([mainOfficeLookup, mobilePhoneTypeLookup]) => {
        Object.assign(e.data, {
          support_type: mainOfficeLookup?.dbId ?? null,
          phone_type: mobilePhoneTypeLookup?.dbId ?? null,
        });
      },
    );
  };

  onRowInserting = e => {
    const { __KEY__: key, ...data } = e?.data;
    const isUniq = this.checkIsPhoneNumberUniq(data);

    if (!isUniq) {
      this.toastrService.error('Same Phone Number already exists in this profile');

      e.cancel = true;
      return;
    }

    const phoneNumbers = this.normalizePhoneNumbers(data);
    phoneNumbers.push(Object.assign({ id: key }, data));
    e.cancel = this.updatePhoneNumbers(phoneNumbers);
  };

  onRowUpdating = e => {
    const data = Object.assign({}, e?.oldData, e?.newData);
    const isUniq = this.checkIsPhoneNumberUniq(data, e?.key);

    if (!isUniq) {
      this.toastrService.error('Same Phone Number already exists in this profile');
      e.cancel = true;
      return;
    }

    const phoneNumbers = this.normalizePhoneNumbers(data, e?.key);
    e.cancel = this.updatePhoneNumbers(phoneNumbers);
  };

  onRowRemoving = e => {
    const phoneNumbers = this.phoneNumbers.filter(phoneNumber => {
      return phoneNumber !== e.key;
    });
    e.cancel = this.updatePhoneNumbers(phoneNumbers);
  };

  onPhoneTypeChanged = ({ selectedItem }): void => {
    this.officePhoneNumberService.setSelectedAgencyPhoneType(selectedItem);
  };

  private checkIsPhoneNumberUniq = (data, key?: PhoneNumber) => {
    return this.phoneNumbers.every(phoneNumber => {
      if (key && phoneNumber === key) {
        return true;
      }

      return data?.number !== phoneNumber?.number;
    });
  };

  private normalizePhoneNumbers = (data, key?: PhoneNumber): PhoneNumber[] => {
    const isPrimary = data?.is_primary;

    return this.phoneNumbers.map(phoneNumber => {
      if (key && phoneNumber === key) {
        return data;
      }

      const normalizedPhoneNumber = Object.assign({}, phoneNumber);
      if (isPrimary) {
        Object.assign(normalizedPhoneNumber, { is_primary: false });
      }

      return normalizedPhoneNumber;
    });
  };

  private updatePhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
    return this.officePhoneNumberService.updatePhoneNumbers(this.agencyId, phoneNumbers).then(() => {
      this.phoneNumbers = phoneNumbers;
      this.phoneNumbersChange.emit(phoneNumbers);
    });
  };
  protected readonly EntityPermissionModelKeys = EntityPermissionModelKeys;
  protected readonly Entity = Entity;
}
