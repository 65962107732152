<ag-shr-modal-window
  #mediaUploaderModalRef
  [width]="'680px'"
  [height]="'700px'"
  [title]="'Upload Media'"
  [useScrollView]="false"
  [saveButtonDisabled]="(isImageSelected$ | async) === false"
  [inProgress]="inProgress$ | async"
  [onCloseModal]="handleClosePopup"
  (onSaveClick)="saveImage()"
>
  <ng-container *ngIf="mediaUploaderModalRef?.popupComponent?.visible ?? false">
    <div class="media-uploader__container" *var="selectedTabIndex$ | async as selectedTabIndex">
      <ag-shr-tabs
        *ngIf="tabConfig?.length > 1"
        class="media-uploader__header"
        [dataSource]="tabConfig"
        [selectedIndex]="selectedTabIndex"
        (onItemClick)="onSelectedIndexChange($event)"
      >
      </ag-shr-tabs>

      <ag-shr-upload-from-disk-tab class="media-uploader__content" [accept]="accept" *ngIf="selectedTabIndex === 0">
        <ng-container *ngTemplateOutlet="fileNameInputTmp; context: { imageData: imageData$ | async }"></ng-container>
      </ag-shr-upload-from-disk-tab>

      <ag-shr-upload-from-url-tab class="media-uploader__content" *ngIf="selectedTabIndex === 1">
        <ng-container *ngTemplateOutlet="fileNameInputTmp; context: { imageData: imageData$ | async }"></ng-container>
      </ag-shr-upload-from-url-tab>

      <ag-shr-select-from-gallery
        *ngIf="selectedTabIndex === 2"
        class="media-uploader__content"
        [mediaItems]="mediaItems"
      >
      </ag-shr-select-from-gallery>
    </div>
  </ng-container>
</ag-shr-modal-window>

<ng-template #fileNameInputTmp let-imageData="imageData">
  <dx-text-box
    [width]="'100%'"
    [(value)]="imageData && imageData.fileName"
    [label]="'File name'"
    [labelMode]="'floating'"
    [showClearButton]="false"
    class="upload-from-disk-tab__text-input"
    [disabled]="!imageData?.base64"
  >
    <dx-validator #fileNameValidatorRef>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dx-validator>
  </dx-text-box>
</ng-template>
