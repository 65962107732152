import { BaseModel } from '../base.model';

export enum MediaContentType {
  image = 'image',
}

export enum AGMediaKeys {
  fileName = 'fileName',
  wasabiPath = 'wasabiPath',
  mediaType = 'mediaType',
  contentType = 'contentType',
}

export class AGMedia extends BaseModel {
  [AGMediaKeys.fileName]: string;
  [AGMediaKeys.wasabiPath]: string;
  [AGMediaKeys.contentType]: string;
  [AGMediaKeys.mediaType]: MediaContentType;

  constructor(mediaType: MediaContentType) {
    super();

    this[AGMediaKeys.mediaType] = mediaType;
  }
}
