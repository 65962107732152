import { Component, HostBinding, Input } from '@angular/core';
import { Address, AddressModelKeys, COUNTRIES, Messages } from '@ag-common-lib/public-api';
import { BehaviorSubject } from 'rxjs';
import { InitializedEvent } from 'devextreme/ui/form';
import { ValidationCallbackData } from 'devextreme/common';

@Component({
  selector: 'ag-shr-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent {
  @HostBinding('class') protected className = 'address-form';

  @Input() pathPrefix: string;
  @Input() address: Partial<Address>;
  @Input() isReadonly: boolean = false;
  @Input() isAddress1Required: boolean = true;
  @Input() isAddress2Required: boolean = false;
  @Input() isCityRequired: boolean = false;
  @Input() isStateRequired: boolean = false;
  @Input() isZipRequired: boolean = false;
  @Input() isCountryRequired: boolean = false;
  @Input() isCountyRequired: boolean = false;
  @Input() isCountryVisible: boolean = true;
  @Input() isCountyVisible: boolean = true;
  @Input() validationGroup: string;

  @Input() colCountByScreen = {
    xs: 1,
    sm: 1,
    md: 12,
    lg: 12,
  };
  @Input() screenLimits;
  protected formSize$ = new BehaviorSubject(null);

  protected readonly AddressModelKeys = AddressModelKeys;
  protected readonly countries = COUNTRIES;
  protected readonly Messages = Messages;

  constructor() {}

  protected onFormInitialized = (e: InitializedEvent) => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === e.element) {
          this.formSize$.next(entry.contentRect.width);
          break;
        }
      }
    });

    resizeObserver.observe(e.element);
  };

  protected address1ValidationCallback = (e: ValidationCallbackData) => {
    if (e.value) {
      return true;
    }

    return !Object.values(this.address ?? {}).some(Boolean);
  };
}
