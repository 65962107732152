import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { ModalWindowComponent } from '../../../../modal-window/modal-window.component';
import {
  ActiveLookup,
  Address,
  AddressModelKeys,
  BaseModelKeys,
  COUNTRIES,
  LookupKeys,
  Messages,
  Patterns,
} from '@ag-common-lib/public-api';
import { AgencyAddressesService } from '../agency-addresses.service';

@Component({
  selector: 'ag-shr-agency-address-modal',
  templateUrl: './agency-address-modal.component.html',
  styleUrls: ['./agency-address-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyAddressModalComponent {
  @ViewChild('agencyAddressFormRef', { static: false }) agencyAddressFormComponent: DxFormComponent;
  @ViewChild('agencyAddressFormModalRef', { static: true }) agencyAddressFormModalComponent: ModalWindowComponent;
  @Input() agencyAddressTypesLookup: ActiveLookup[];
  @Input() statesLookup: ActiveLookup[];
  @Output() addressChange = new EventEmitter<Address>();

  agencyAddressFormData: Address;
  agencyId: string;
  inProgress$ = this.agencyAddressesService.inProgress$;

  protected readonly BaseModelKeys = BaseModelKeys;
  protected readonly LookupKeys = LookupKeys;
  protected readonly Messages = Messages;
  protected readonly countries = COUNTRIES;
  protected readonly usZipRegExp = Patterns.US_ZIP;
  protected readonly AddressModelKeys = AddressModelKeys;

  constructor(private agencyAddressesService: AgencyAddressesService) {}

  handleSaveAgencyAddress = e => {
    const validationResults = this.agencyAddressFormComponent.instance.validate();
    if (validationResults.isValid) {
      this.addressChange.emit(this.agencyAddressFormData);
    }
  };

  onAfterRevertChanges = (): void => {
    this.agencyAddressFormComponent.instance.repaint();
  };

  handleClosePopup = () => this.agencyAddressesService.onCancelEdit.call(this, this.onAfterRevertChanges);

  onSelectedAddressTypesChanged = ({ selectedItem }): void => {
    this.agencyAddressesService.setSelectedAgencyType(selectedItem);
  };

  showModal = async (agencyId: string, data?: Address): Promise<void> => {
    this.agencyId = agencyId;
    this.agencyAddressFormData = await this.agencyAddressesService.getFormData(data);
    this.agencyAddressFormModalComponent?.showModal();
  };

  hideModal = () => {
    this.agencyAddressFormModalComponent.hideModal();
  };
}
