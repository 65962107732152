<dx-popup
  #popupRef
  [deferRendering]="false"
  [showCloseButton]="showCloseButton"
  [resizeEnabled]="true"
  [width]="width"
  [height]="height"
  maxHeight="100vh"
  [fullScreen]="isFullScreen"
  [wrapperAttr]="{ class: 'modal-window__popup ' + customClass }"
  (onInitialized)="onInitialized($event)"
  (onHiding)="handleClosePopup($event)"
  (onShowing)="onShowing.emit($event)"
  (onShown)="onShown.emit($event)"
  (onHidden)="onHidden.emit($event)"
>
  <dxi-toolbar-item [visible]="!!title" location="before">
    <div *dxTemplate>
      <span class="modal-window__title">{{ title }}</span>
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [visible]="showFullScreenButton"
    [options]="{
      stylingMode: 'text',
      icon: 'fullscreen',
      onClick: toggleScreenSize
    }"
  >
  </dxi-toolbar-item>

  <dxi-toolbar-item
    *ngFor="let toolbarItem of extraToolbarItems"
    [widget]="toolbarItem.widget"
    [visible]="toolbarItem.visible"
    [location]="toolbarItem.location"
    [toolbar]="toolbarItem.toolbar"
    [options]="toolbarItem.options"
  >
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [visible]="showCancelButton"
    [options]="{
      text: cancelTitle,
      onClick: hideModal,
      elementAttr: {
        class: 'modal-window__toolbar-button'
      }
    }"
  >
  </dxi-toolbar-item>

  <dxi-toolbar-item [visible]="showSaveButton" widget="dxButton" location="after" toolbar="bottom">
    <div *dxTemplate>
      <shr-button-with-indicator
        [title]="actionTitle"
        [disabled]="saveButtonDisabled"
        [cssClass]="'modal-window__toolbar-button'"
        [isInProgress]="inProgress"
        (onClick)="handelSaveClick()"
      ></shr-button-with-indicator>
    </div>
  </dxi-toolbar-item>

  <div class="modal-window-main-layout">
    <div class="modal-window-main-layout__content modal-window-main-layout__content--modal">
      <dx-scroll-view
        *ngIf="useScrollView; else content"
        height="100%"
        direction="vertical"
        [useNative]="false"
        [showScrollbar]="'always'"
      >
        <ng-container *ngTemplateOutlet="content"> </ng-container>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>

<ng-template #content> <ng-content></ng-content> </ng-template>
