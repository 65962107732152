import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AgMediaUploaderService, ImageData } from './ag-media-uploader.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { confirm } from 'devextreme/ui/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DxValidatorComponent } from 'devextreme-angular';
import { AGMedia } from '../../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/ag-media.model';
import { UploadFromDiskTabComponent } from './upload-from-disk-tab/upload-from-disk-tab.component';
import { UploadFromUrlTabComponent } from './upload-from-url-tab/upload-from-url-tab.component';
import { MediaUploaderTabs } from './ag-media-uploader.models';

@UntilDestroy()
@Component({
  selector: 'ag-shr-media-uploader',
  templateUrl: './ag-media-uploader.component.html',
  styleUrls: ['./ag-media-uploader.component.scss'],
  providers: [AgMediaUploaderService],
})
export class AgMediaUploaderComponent implements OnInit, OnChanges {
  @Input() accept = 'image/*';
  @Input() set mediaPathPrefix(mediaPathPrefix: string) {
    this.mediaUploaderService.setPrefix(mediaPathPrefix);
  }
  @Input() mediaSources: MediaUploaderTabs[];
  @Input() mediaItems: AGMedia[];

  @Output() mediaChanged = new EventEmitter<AGMedia>();

  @ViewChild('mediaUploaderModalRef', { static: true }) mediaUploaderModalComponent: ModalWindowComponent;
  @ViewChild('fileNameValidatorRef', { static: false }) fileNameValidatorComponent: DxValidatorComponent;
  @ViewChild(UploadFromDiskTabComponent, { static: false }) uploadFromDiskTabComponent: UploadFromDiskTabComponent;
  @ViewChild(UploadFromUrlTabComponent, { static: false }) uploadFromUrlTabComponent: UploadFromUrlTabComponent;

  protected tabConfig: any = [
    { text: MediaUploaderTabs.FromFile },
    { text: MediaUploaderTabs.ByURL },
    { text: MediaUploaderTabs.Gallery, visible: true },
  ];

  selectedTabIndex$ = new BehaviorSubject(0);
  inProgress$: Observable<boolean>;
  isImageSelected$: Observable<boolean>;
  imageData$: Observable<ImageData>;

  constructor(private mediaUploaderService: AgMediaUploaderService) {
    this.inProgress$ = this.mediaUploaderService.inProgress$;
    this.isImageSelected$ = this.mediaUploaderService.isImageSelected$;
    this.imageData$ = this.mediaUploaderService.image$;
  }

  ngOnInit(): void {
    this.tabConfig = (
      this.mediaSources ?? [MediaUploaderTabs.FromFile, MediaUploaderTabs.ByURL, MediaUploaderTabs.Gallery]
    )?.map(mediaSource => ({ text: mediaSource }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mediaSources) {
      this.tabConfig = changes.mediaSources.currentValue?.map(mediaSource => ({ text: mediaSource }));
    }
  }

  async saveImage() {
    let wasabiUrl;
    switch (this.selectedTabIndex$.value) {
      case 2:
        wasabiUrl = this.mediaUploaderService.selectedFromGallery$.value;
        break;
      default:
        const validationResults = this.fileNameValidatorComponent?.instance.validate();

        if (!validationResults.isValid) {
          return;
        }

        wasabiUrl = await this.mediaUploaderService.saveImage();
    }

    this.mediaChanged.emit(wasabiUrl);

    this.mediaUploaderModalComponent.hideModal();
  }

  onAfterRevertChanges = (): void => {
    this.uploadFromDiskTabComponent?.clear();
    this.uploadFromUrlTabComponent?.clear();
    this.fileNameValidatorComponent?.instance.reset();
  };

  handleClosePopup = () => this.mediaUploaderService.onCancelEdit.call(this, this.onAfterRevertChanges);

  showModal() {
    this.selectedTabIndex$.next(0);
    this.mediaUploaderService.setImage();
    this.mediaUploaderModalComponent.showModal();
  }

  onSelectedIndexChange = async e => {
    const isImageSelected = await firstValueFrom(this.isImageSelected$);

    if (!isImageSelected) {
      this.selectedTabIndex$.next(e?.itemIndex);
      return;
    }

    const continueWithoutSaving = await confirm(
      '<i>You are have not saved data. <br/> Are you sure you want to Switch without Saving?</i>',
      'Confirm',
    );

    if (continueWithoutSaving) {
      this.mediaUploaderService.setSelectedFromGallery(null);
      this.mediaUploaderService.setImage(null);
      this.selectedTabIndex$.next(e?.itemIndex);
    }

    const currentIndex = this.selectedTabIndex$.value;
    this.selectedTabIndex$.next(null);
    setTimeout(() => {
      this.selectedTabIndex$.next(currentIndex);
    }, 0);
  };
}
