import { Pipe, PipeTransform } from '@angular/core';
import { Agent, AgentKeys } from 'ag-common-lib/public-api';
import { AgencyService } from '../../public-api';

@Pipe({ name: 'agentMainInfoAgency' })
export class AgentMainInfoAgencyPipe implements PipeTransform {
  constructor(private agencyService: AgencyService) {}

  transform(agentData: Agent): Promise<string[]> {
    const promisees = [];
    const mgaId = agentData[AgentKeys.p_mga_id];

    if (mgaId) {
      promisees.push(
        this.agencyService.getAgencyByAgencyId(mgaId).then(agency => (agency?.name ? `MGA: ${agency?.name}` : '')),
      );
    }

    const agencyId = agentData[AgentKeys.p_agency_id];

    if (agencyId !== mgaId) {
      promisees.push(
        this.agencyService
          .getAgencyByAgencyId(agencyId)
          .then(agency => (agency?.name ? `Agency: ${agency?.name}` : '')),
      );
    }

    return Promise.all(promisees?.filter(Boolean)).then(items => items?.filter(Boolean));
  }
}
