<dx-form
  #addressFormRef
  [formData]="address"
  labelMode="floating"
  [validationGroup]="validationGroup"
  [screenByWidth]="formSize$ | async | formScreenByWidth"
  (onInitialized)="onFormInitialized($event)"
  (onFieldDataChanged)="addressFormRef?.instance?.validate()"
>
  <dxi-item
    [dataField]="AddressModelKeys.address1"
    [label]="{ text: 'Address 1' }"
    [isRequired]="isAddress1Required"
    [editorOptions]="{
      name: [pathPrefix, AddressModelKeys.address1] | path,
      readOnly: isReadonly,
      inputAttr: { autocomplete: 'disabled' }
    }"
  >
    <dxi-validation-rule
      type="custom"
      [validationCallback]="address1ValidationCallback"
      [message]="Messages.REQUIRED_TEXT"
      [reevaluate]="true"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    [dataField]="AddressModelKeys.address2"
    [label]="{ text: 'Address 2' }"
    [isRequired]="isAddress2Required"
    [editorOptions]="{
      name: [pathPrefix, AddressModelKeys.address2] | path,
      readOnly: isReadonly,
      inputAttr: { autocomplete: 'disabled' }
    }"
  ></dxi-item>

  <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
    <dxi-item
      [dataField]="AddressModelKeys.city"
      [label]="{ text: 'City' }"
      [colSpan]="6"
      [isRequired]="isCityRequired"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.city] | path,
        readOnly: isReadonly,
        inputAttr: { autocomplete: 'disabled' }
      }"
    ></dxi-item>

    <dxi-item [colSpan]="3">
      <div *dxTemplate>
        <ag-shr-state-select-box
          [name]="[pathPrefix, AddressModelKeys.state] | path"
          [(value)]="address && address[AddressModelKeys.state]"
          [readOnly]="isReadonly"
          [validationGroup]="validationGroup"
          [isRequired]="isStateRequired"
        ></ag-shr-state-select-box>
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.zip"
      [label]="{ text: 'Zip Code' }"
      [colSpan]="3"
      [isRequired]="isZipRequired"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.zip] | path,
        readOnly: isReadonly,
        inputAttr: { autocomplete: 'disabled' }
      }"
    ></dxi-item>
  </dxi-item>

  <dxi-item itemType="group" [colCountByScreen]="colCountByScreen">
    <dxi-item
      [dataField]="AddressModelKeys.country"
      [label]="{ text: 'Country' }"
      editorType="dxSelectBox"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.country] | path,
        acceptCustomValue: true,
        items: countries,
        valueExpr: 'name',
        displayExpr: 'name',
        searchEnabled: true,
        readOnly: isReadonly,
        placeholder: '',
        inputAttr: { autocomplete: 'disabled' }
      }"
      [isRequired]="isCountryRequired"
      [visible]="isCountryVisible"
      [colSpan]="6"
    ></dxi-item>

    <dxi-item
      [dataField]="AddressModelKeys.county"
      [label]="{ text: 'County' }"
      [colSpan]="6"
      [visible]="isCountyVisible"
      [isRequired]="isCountyRequired"
      [editorOptions]="{
        name: [pathPrefix, AddressModelKeys.county] | path,
        readOnly: isReadonly,
        inputAttr: { autocomplete: 'disabled' }
      }"
    ></dxi-item>
  </dxi-item>
</dx-form>
