import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { AGMedia, AGMediaKeys } from '@ag-common-lib/public-api';

export interface ImageData {
  contentType: string;
  fileName: string;
  base64: string;
}
@Injectable()
export class AgMediaUploaderService {
  private _image$ = new BehaviorSubject<ImageData>(null);
  image$ = this._image$.asObservable();

  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  selectedFromGallery$ = new BehaviorSubject<AGMedia>(null);

  isImageSelected$ = combineLatest([this._image$, this.selectedFromGallery$]).pipe(
    map(([image, selectedFormGallery]) => !!image?.base64 || !!selectedFormGallery),
  );

  private _urlPrefix: string;

  constructor(private toastrService: ToastrService, private cloudFunctions: CloudFunctionsService) {}

  setImage = (image?: ImageData) => {
    this._image$.next(image);
  };

  setSelectedFromGallery = (mediaItem: AGMedia) => {
    this.selectedFromGallery$.next(mediaItem);
  };

  setPrefix = (urlPrefix: string) => {
    this._urlPrefix = urlPrefix;
  };

  saveImage = (): Promise<Partial<AGMedia> | void> => {
    this._inProgress$.next(true);

    const { base64, contentType, fileName } = this._image$.value;

    return this.cloudFunctions
      .uploadMedia({
        mediaData: base64,
        filePath: this._urlPrefix,
        contentType: contentType,
        metadata: {
          fileName,
        },
      })
      .then(path => {
        this.toastrService.success('Image Successfully Uploaded');

        return {
          [AGMediaKeys.wasabiPath]: path,
          [AGMediaKeys.fileName]: fileName,
          [AGMediaKeys.contentType]: contentType,
        };
      })
      .catch(e => {
        this.toastrService.error('Failed to Upload Image');
      })
      .finally(() => {
        this._inProgress$.next(false);
      });
  };

  onCancelEdit = async (onAfterRevertChanges?: () => void): Promise<boolean> => {
    this.selectedFromGallery$.next(null);
    this._image$.next(null);

    onAfterRevertChanges && onAfterRevertChanges();
    return true;
  };
}
