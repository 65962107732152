import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import {
  EntityPermissionActivityKeys,
  EntityPermissionModelKeys,
  RoleEntityPermissionMap,
} from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/models/utils/entity-permission.model';
import { Entity } from '../../../../../../ag-common-lib/projects/ag-common-lib/src/lib/lists/entity-permission.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  permissions$: Observable<RoleEntityPermissionMap>;

  constructor() {}

  async hasPermission(entity: Entity, entityPermissionKey?: EntityPermissionActivityKeys): Promise<boolean> {
    const permissionsMap = await firstValueFrom(this.permissions$);

    if (!permissionsMap[entity]) {
      return false;
    }

    if (entityPermissionKey) {
      return permissionsMap[entity][entityPermissionKey];
    }
    return (
      permissionsMap[entity][EntityPermissionActivityKeys.create] ||
      permissionsMap[entity][EntityPermissionActivityKeys.read] ||
      permissionsMap[entity][EntityPermissionActivityKeys.write] ||
      permissionsMap[entity][EntityPermissionActivityKeys.delete]
    );
  }
}
